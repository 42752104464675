import type { DeepReadonly } from "vue";
import type { ErrorResponse } from "@newgenerated/shared/schema";
import { deserialize_ErrorResponse } from "@newgenerated/shared/mapper";
import { isAxiosError } from "axios";

export type Result<T> = { state: "ERROR"; msg: string; errorResponse?: ErrorResponse } | { state: "SUCCESS"; value: T } | { state: "IGNORE" };

export async function saveAsyncRequest<T, V extends unknown[]>(call: (...params: DeepReadonly<V>) => Promise<T>, isLastRequest: boolean | ((...params: DeepReadonly<V>) => boolean), ...params: DeepReadonly<V>): Promise<Result<T>> {
  const checkIsLastRequest = (isLastRequest: boolean | ((...params: DeepReadonly<V>) => boolean), ...params: DeepReadonly<V>): boolean => {
    if (typeof isLastRequest === "boolean") {
      return isLastRequest;
    }
    return isLastRequest(...params);
  };
  try {
    const response = await call(...params);
    if (checkIsLastRequest(isLastRequest, ...params)) {
      return { state: "SUCCESS", value: response };
    }
    return { state: "IGNORE" };
  } catch (e) {
    if (checkIsLastRequest(isLastRequest, ...params)) {
      if (isAxiosError(e) && e.response?.data !== undefined) {
        const errorResponse = deserialize_ErrorResponse(e.response.data);
        if (errorResponse !== null) {
          return { state: "ERROR", msg: e instanceof Error ? e.message : "", errorResponse: errorResponse };
        }
      }
      return { state: "ERROR", msg: e instanceof Error ? e.message : "" };
    }
    return { state: "IGNORE" };
  }
}
