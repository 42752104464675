import type { GaVueComponent } from "@/common/vueUtils";

export function GaFormFieldTextArea(props: { value: string; label?: string; onUpdateValue: (newValue: string) => void; name: string; placeholder?: string; rows?: number }): GaVueComponent {
  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}
        </label>
      ) : null}
      <textarea rows={props.rows} name={props.name} placeholder={props.placeholder} value={props.value} onInput={(e) => props.onUpdateValue((e.target as HTMLInputElement).value)} class="form-control" />
    </div>
  );
}
